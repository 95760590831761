<template>
    <el-dialog :visible.sync="visible" :custom-class="customDialogClass"
               :title="'编辑农田'"
               width="40vw" :modal="false" style="z-index: 9999">
        <div
                style="margin-left: 15px;margin-right: 15px;height: 60vh;overflow-y: scroll">
            <el-form labelPosition="top" labelAlign="left" labelWidth="100vw" errorType="toast"
                     v-loading="loading"
                     :element-loading-text="loadingText"
                     element-loading-spinner="el-icon-loading"
                     element-loading-background="rgba(0, 0, 0, 0.8)"
                     :label-style="{fontWeight: `bold`,color: `rgba(16,16,16,0.7)`}" :model="formData" :rules="rules"
                     ref="landEditRef">
                <el-form-item label="农田名称" prop="name">
                    <el-input v-model="formData.name" placeholder="请输入农田名称" border="none"></el-input>
                </el-form-item>
                <el-form-item label="农田封面" prop="imgUrl">

                    <el-upload
                            class="avatar-uploader"
                            :action="uploadUrl"
                            :headers="{'token': $token}"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="formData.imgUrl" :src="formData.imgUrl" class="avatar" alt="">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <div style="height: 20px"></div>
                </el-form-item>
                <el-form-item label="农田简介" prop="content">
                    <el-input
                            type="textarea"
                            :autosize="{ minRows: 3, maxRows: 8}"
                            placeholder="请输入农田简介"
                            v-model="formData.content">
                    </el-input>
                </el-form-item>
                <el-form-item label="云台摄像设备ID" prop="cameraConfig">
                    <el-input v-model="formData.cameraConfig" placeholder="请输入云台摄像设备ID"
                              border="none"></el-input>
                </el-form-item>
                <el-form-item label="土壤Topic" prop="soilTopic" borderBottom>
                    <el-input v-model="formData.soilTopic" placeholder="请输入土壤Topic" border="none"></el-input>
                </el-form-item>
                <el-form-item label="灌溉Topic" prop="wateringTopic" borderBottom>
                    <el-input v-model="formData.wateringTopic" placeholder="请输入灌溉Topic" border="none"></el-input>
                </el-form-item>

                <el-form-item label="土壤传感器"  >

                    <el-select v-model="formData.soilConfig" placeholder="请选择土壤传感器" style="width: 100%"
                               @change="onSelectSoil">
                        <el-option
                                v-for="item in soilList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        />
                    </el-select>

                </el-form-item>
                <el-form-item label="种植状态" prop="status">
                    <el-select v-model="formData.status" placeholder="请选择种植状态" style="width: 100%"
                               @change="onSelectStatus">
                        <el-option
                                v-for="item in farmLandStatus"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <template #footer>
            <el-button-group style="box-shadow: 0 0 10px 10px rgba(80, 227, 194, 0.3)">
                <el-button type="primary" size="medium"
                           style="font-size:20px;background: black;border: rgba(14, 246, 195, 0.7) 1px solid;color: rgba(14, 246, 195, 0.7);font-weight: bold"
                           plain @click="saveData()">保存
                </el-button>
                <el-button v-if="formData && formData.id" size="medium" @click="deleteData()"
                           style="font-size:20px;background: rgba(77,40,40,0.5);border: rgba(14, 246, 195, 0.7) 1px solid;color: rgba(14, 246, 195, 0.7);font-weight: bold">
                    删除
                </el-button>
            </el-button-group>
        </template>
    </el-dialog>
</template>
<script>
import {mapState} from 'vuex';

export default {
    name: 'ui-farm-land-edit',
    props: {},
    data() {
        return {
            uploadUrl: 'https://iot.xyxedu.cn/api/sys/oss/uploadImage',
            customDialogClass: 'topic-dialog-cls', // 自定义对话框的背景样式
            loading: false,
            visible: false,
            formData: {
                status: 0,
                fid: '',
                cameraConfig: '',
                wateringTopic: '',
                soilTopic: '',
                weatherTopic:'',
                soilConfig: '',
            },
            loadingText: '加载中...',
            rules: {
                name: [
                    {required: true, message: '请输入农田名称', trigger: 'blur'},
                ],
                imgUrl: [
                    {required: true, message: '请上传农田封面', trigger: 'blur'},
                ],
                content: [
                    {required: true, message: '请输入农田简介', trigger: 'blur'},
                ],
                cameraConfig: [
                    {required: true, message: '请输入云台摄像设备ID', trigger: 'blur'},
                ],
                wateringTopic: [
                    {required: true, message: '请输入灌溉Topic', trigger: 'blur'},
                ],
                // soilConfig: [
                //     {required: true, message: '请选择土壤传感器', trigger: 'blur'},
                // ],
                status: [
                    {required: true, message: '请选择种植状态', trigger: 'blur'},
                ],
            },
            farmLandStatus: [{
                label: '已完结',
                value: 0,
            }, {
                label: '种植中',
                value: 1,
            }],
            soilList: [],
            farmLandId: '',
        };
    },
    computed: {
        ...mapState({
            $token: state => state.$token,
            $farmInfo: state => state.$farmInfo,
            $farmDetailInfo: state => state.$farmDetailInfo,
            $farmUser: state => state.$farmUser,
        }),
    },
    mounted() {


    },
    methods: {
        open(farmLandItem) {
            if(farmLandItem && farmLandItem.id){
                this.loading = true
                this.loadData(farmLandItem)
            }
            else {
                this.formData = Object.assign(farmLandItem, {
                    status: 0,
                    cameraConfig: '',
                    wateringTopic: '',
                    soilTopic: '',
                    weatherTopic:'',
                    soilConfig: '',
                })
                this.loading = false
            }
            this.visible = true
        },
        loadData(farmLandItem) {
            this.loadingText = '加载中...'
            this.$http.get('app/appfarmland/info', {
                params: {
                    id: farmLandItem.id,
                    fid: farmLandItem.fid,
                    farmUserId: this.$farmUser.id,
                },
            }).then(res => {
                this.formData = res.data.data;
                this.fileList = [{
                    url: this.formData.imgUrl,
                }]
                this.toShowSoil()
                setTimeout(() => {
                    this.loading = false
                }, 1000)
            }).catch(errors => {
                console.log(errors)
            })
        },
        toShowSoil() {
            if (this.soilList == null || this.soilList.length === 0) {
                this.$http.get(`app/iot/soil/topicList?topic=${this.formData.soilTopic?this.formData.soilTopic:this.formData.weatherTopic}`).then(res => {
                    if (res) {
                        this.soilList = res.data.data.map((item) => {
                            return {
                                label: item.name,
                                value: item.key,
                            }
                        })
                    }
                }).catch(errors => {
                    console.log(errors)
                })
            }
        },
        onSelectSoil(value) {
            this.formData.soilName = value
        },
        onSelectStatus(value) {
            this.formData.status = value
        },
        saveData() {
            this.$refs.landEditRef.validate((valid) => {
                if (valid) {
                    this.loadingText = '正在保存...'
                    this.loading = true
                    this.formData.farmUserId = this.$farmUser.id
                    this.$http.post('app/appfarmland/save', this.formData).then(res => {
                        this.$message.success('保存成功')
                        this.$emit('landSaveSuccess')
                        this.loading = false
                        this.visible = false
                    })
                } else {
                    return false;
                }
            });
        },
        deleteData() {

            this.$msgbox({
                title: '提示',
                message: '确定删除吗？',
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.doDelete()
            }).catch(() => {
            });


        },
        doDelete() {
            this.$http.delete('app/appfarmland/delete', {
                data: {
                    id: this.formData.id,
                    farmUserId:this.$farmUser.id
                },
            }).then(res => {
                this.$message.success('删除成功')
                this.$emit('landSaveSuccess')
                this.visible = false
            })
        },
        handleAvatarSuccess(res, file) {
            this.formData.imgUrl = res.data.src
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
    },
}

</script>
<style lang="scss">

.topic-dialog-cls .el-dialog {
    border-radius: 15px 15px 8px 8px;
    background: transparent;
    border: rgba(14, 246, 195, 0.7) 4px solid;
    box-shadow: 0 0 10px 10px rgba(80, 227, 194, 0.3); /* 阴影 */
    outline: 5px solid #07b08a; /* 描边 */
    outline-offset: -5px; /* 描边偏移量，使其与元素大小相同 */
}

.topic-dialog-cls .el-dialog__header {
    border-radius: 15px 15px 0 0;
    background-color: rgb(11, 32, 51);
}

.topic-dialog-cls .el-dialog__title {
    color: #00ebef;
    font-size: 20px;
    font-weight: bold;
}

/*body背景色*/
.topic-dialog-cls .el-dialog__body {
    border-radius: 0 0 8px 8px;
    background-color: rgba(0, 0, 0, 0.92);
}

.topic-dialog-cls .el-select__tags-text {
    color: #4ECFDB;
    font-size: 14px;
}

.topic-dialog-cls .el-tag.el-tag--info {
    background: black;
}

.topic-dialog-cls .el-tag.el-tag--info .el-tag__close {
    color: #4ECFDB;
    background: black;
}


.el-select--medium {
    background: black;
}

.el-select-dropdown {
    background: #020308;
    border: #5cd9e8 2px solid;
    border-radius: 5px;
    color: #4ECFDB;
}

.el-select-dropdown__item {
    color: rgba(78, 207, 219, 0.7);
    font-size: 20px;
}

.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
    background: rgba(0, 235, 239, 0.2);
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    background: rgba(0, 235, 239, 0.4);
    color: white;
}

.el-popper[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: transparent;
}

.el-popper .popper__arrow, .el-popper .popper__arrow::after {
    border-style: none !important;
}

.topic-dialog-cls .el-form-item--small .el-form-item__content, .el-form-item--small .el-form-item__label {
    line-height: 40px;
    color: #00ebef;
    font-size: 20px;
}

.el-input--small .el-input__inner {
    background: transparent;
    color: white;
    font-size: 20px;
    height: 40px;
    border-color: rgba(0, 235, 239, 0.4);
}

.el-input--medium .el-input__inner {
    background: transparent;
    color: white;
    font-size: 20px;
    height: 40px;
    border-color: rgba(0, 235, 239, 0.4);
}

::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

.el-textarea__inner {
    font-size: 20px;
    color: white;
    background: transparent;
}

.avatar-uploader .el-upload {
    border: 2px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 600px;
    height: 200px;
    line-height: 200px;
    text-align: center;
}
.avatar {
    width: 600px;
    height: 200px;
    display: block;
}
</style>
